import Map from '../src/ol/Map.js';
import OSM from '../src/ol/source/OSM.js';
import TileLayer from '../src/ol/layer/Tile.js';
import View from '../src/ol/View.js';

const map = new Map({
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
  ],
  view: new View({
    center: [0, 0],
    zoom: 2,
  }),
});

map.setTarget('map1');

const teleportButton = document.getElementById('teleport');

teleportButton.addEventListener(
  'click',
  function () {
    const target = map.getTarget() === 'map1' ? 'map2' : 'map1';
    map.setTarget(target);
  },
  false,
);
